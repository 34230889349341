$particles: 50;
$width: 1000;
$height: 1000;

$shadows1: ();
$shadows2: ();

@for $i from 0 to $particles {

    $w: random( $width ) - $width / 2;
    $h: random( $height ) - $height / 1.2;

    $shadows1: $shadows1, #{$w}px #{$h}px hsl( random(360), 100, 50 );
    $shadows2: $shadows2, 0 0 $color-white;

}

@keyframes bang {

    to {

        box-shadow: $shadows1;

    }

}

@keyframes gravity {

    to {

        opacity: 0;
        transform: translateY(400px);

    }

}

@keyframes position {

    0%, 19.9% {

        margin-top: 10%;
        margin-left: 40%;

    }

    20%, 39.9% {

        margin-top: 40%;
        margin-left: 30%;

    }

    40%, 59.9% {

        margin-top: 20%;
        margin-left: 70%;

    }

    60%, 79.9% {

        margin-top: 30%;
        margin-left: 20%;

    }

    80%, 99.9% {

        margin-top: 30%;
        margin-left: 80%;

    }

}

@mixin fireworks {

    overflow: hidden;

    &:before,
    &:after {

        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        box-shadow: $shadows2;
        background: $color-red;
        animation: ( 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards );
        content: "";
        z-index: 1000;

    }

    &:after {

        animation-delay: ( 1.25s, 1.25s, 1.25s );
        animation-duration: ( 1.25s, 1.25s, 6.25s );

    }

}
