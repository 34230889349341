
@import "Import/SCSS/keyframes";
@import "Import/SCSS/classes";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

* {

    @include vendor-prefix( appearance, none );

    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );

}

body,
input,
textarea,
select {

    color: $color-white;
    font-family: 'Open Sans', sans-serif;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );

}

html,
body,
#root,
.Core {

    position: relative;
    height: 100%;
    width: 100%;
    background: $color-red;
    transition: background 1s ease;
    overflow: hidden;

}

b,
strong {

    font-weight: 700;

}

i {

    font-weight: 700;

}

hr {

    @include gradient-line;

    display: block;
    width: 100%;
    height: 2px;
    margin: 40px 0;
    border: none;

    @include responsive-width-or-height( $w1, $h1 ) {

        height: 2px;
        margin: 20px 0;
        
    }

}

p {

    margin: $line-height 0;

}