
@import "../../../Import/SCSS/classes";

.Buttons {

    margin: 42px 0 0;
    
}

@include responsive-width-or-height( 760px, 760px ) {

    .Button {

        margin: 0 !important;

    }

    .Buttons {

        margin: 20px 0 0;
        
    }

    .ButtonContainer {

        display: inline-block;
        transform: scale( .8 );

    }

}