
@import "../../../../Import/SCSS/classes";

.TextField {

    position: relative;

    .Input {

        display: block;
        position: relative;
        width: 100%;
        height: 78px;
        max-width: $h1;
        margin: 20px auto;
        color: currentColor;
        background: transparent;
        border: none;
        border-radius: 0;
        outline: none;
        font-size: $font-size;
        font-weight: 400;
        border-bottom: 2px solid currentColor;
        box-sizing: border-box;

        @include input-placeholder {

            color: currentColor;

        }

    }

    &:first-child .Input {

        margin-top: 0;

    }

    &.Disabled {

        .Input {

            opacity: .5;

        }

    }

    @include responsive-width-or-height( 760px, 760px ) {

        .Input {

            height: 56px;
            font-size: $font-size-narrow;

        }

    }

}
