
@import "../../../Import/SCSS/classes";

.Share {

    @include page;

}

.ShareContent {

    @include content;

}