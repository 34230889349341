
@import "../../../Import/SCSS/classes";

.LoadImage {

    @include overlay;
    
    transition: opacity .5s;
    background-size: cover;

    &.Loaded {

        opacity: 1;

    }

}