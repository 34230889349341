
@import "../../../Import/SCSS/classes";

.Register {

    @include page;

}

.RegisterContent {

    @include content;

    p {

        max-width: 600px;
        font-size: 18px !important;
        line-height: 22px !important;
        text-align: left;

        @include responsive-width-or-height( 760px, 760px ) {

            margin: 20px auto;
            font-size: 16px !important;
            line-height: 20px !important;

        }

    }

}