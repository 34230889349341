
@import "../../../../Import/SCSS/classes";

.Checkbox {

    @include no-select;

    position: relative;
    max-width: $h1;
    margin: 40px auto;
    text-align: left;
    line-height: 28px;

    p {

        position: relative;
        overflow: hidden;
        font-size: 20px;
        line-height: 26px;

    }

    .Icon {

        display: block;
        top: 1px;
        width: 100%;
        height: 100%;
        padding: 2px;
        box-sizing: border-box;
        color: $color-blue;

        svg {

            display: block;
            width: 100%;
            height: 100%;
            stroke-width: 4;

        }

    }

    .Input {

        display: block;
        position: relative;
        float: left;
        width: 24px;
        height: 24px;
        margin: 2px 15px 2px 0;
        background-color: currentColor;
        border-radius: 2px;
        outline: none;
        cursor: pointer;
        animation: scale-in 1s ease;
        transition: all .2s ease;

        @include hover {

            transform: scale( 1.1 );

        }

        @include active {

            transform: scale( 1 );

        }

        &.Disabled {

            opacity: .5;
            transform: none !important;

        }

    }

}