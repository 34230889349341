
@import "../../../Import/SCSS/classes";

.Slide {

    $offset: 20px;

    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translate(0,-50%);
    transition: all $duration-normal ease;
    z-index: 10;

    &.After {

        transform: translate(#{$offset},-50%);
        opacity: 0;
        z-index: 1;
    
    }

    &.Before {

        transform: translate(#{-$offset},-50%);
        opacity: 0;
        z-index: 1;
    
    }

}

.Slider {

    position: relative;
    max-width: 1100px;
    margin: 0 auto;
    padding: 15px 0 0;
    background: linear-gradient( to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.05) 50%,rgba(0,0,0,0) 100% );
    transition: height $duration-normal ease;

}

.SliderButtons {

    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -67px 0 0;

    .Button {

        display: block;

    }

    .ButtonContainer {

        display: block;
        transform: scale(.5);
        float: left;

        &:last-child {

            float: right;

        }

    }

}

.Slides {

    position: relative;
    height: 100%;
    margin: 0 140px;

}

@include responsive-width( 1024px ) {

    .Slider {

        margin-bottom: 130px;

        .WebVersion & {

            margin-bottom: 100px;

        }

    }

    .SliderButtons {

        top: 100%;
        margin-top: -50px;

    }

    .Slides {

        margin: 0;

    }

}

@include responsive-width-or-height( 760px, 760px ) {

    .Slider {

        margin-bottom: 200px;

    }

    .SliderButtons {

        top: 100%;
        margin-top: 0;

    }

}