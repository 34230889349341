
@import "../../../Import/SCSS/classes";

.Center {

    position: relative;
    height: 100%;
    padding: 120px 20px;
    box-sizing: border-box;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @include responsive-width( 760px ) {

        padding: 100px 20px;

    }

}

.CenterAlign {

    display: table-cell;
    position: relative;
    vertical-align: middle;

}

.CenterContainer {

    display: table;
    position: relative;
    width: 100%;
    height: 100%;

}
