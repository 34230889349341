
@import "../../../Import/SCSS/classes";

.ScreenTerms {

    @include page;

    .ScreenTermsContent {

        @include content;

    }

}