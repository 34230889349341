
@import "../../../Import/SCSS/classes";

.ScreenField {

    @include page;

    .Input {

        display: block;
        position: relative;
        width: 100%;
        height: 78px;
        max-width: $h1;
        margin: 20px auto;
        color: currentColor;
        background: transparent;
        border: none;
        border-radius: 0;
        outline: none;
        font-size: $font-size;
        font-weight: 400;
        border-bottom: 2px solid currentColor;
        box-sizing: border-box;

        @include input-placeholder {

            color: currentColor;

        }

    }

}

.ScreenFieldContent {

    @include content;

}
