
@import "../../../Import/SCSS/classes";

.About {

    @include page;

}

.AboutContent {

    @include content;

}