
@import "../../../Import/SCSS/classes";

.Admin {

    position: fixed;
    right: 0;
    bottom: 0;
    padding: 10px;
    z-index: 10000;
    overflow: hidden;

}

.AdminMenu {

    $size: 30px;

    position: relative;
    background: rgba( 0, 0, 0, .5 );
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    transition: all .2s ease;
    opacity: 0;

    .Icon {

        display: inline-block;
        position: relative;
        width: $size;
        height: $size;
        margin: 5px;
        vertical-align: middle;
        opacity: .8;
        cursor: pointer;

        svg {

            stroke-width: 1.5;

        }

        &:hover {

            opacity: 1;

        }

    }

    &:hover {

        opacity: 1;

    }

}