@import "../../../Import/SCSS/classes";

.Footer {

    position: absolute;
    right: 0;
    bottom: 0;
    padding: 30px;
    transform: translateY(100%);
    transition: transform .5s ease;
    z-index: 1000;

    .Sida {

        float: right;

    }

    &.Show {

        transform: translateY(0%);

    }

    @include responsive-width( 760px ) {

        padding: 20px;

    }

}