
@import "vars";
@import "mixins";

@mixin citation {

    font-size: 14px;
    line-height: 18px;

    a {

        color: inherit;

    }

}

@mixin content {

    $max-width: 650px;

    margin: 0 auto 100px;
    text-align: center;

    h2 {

        @include title-mid;

        max-width: $max-width;
        margin: 0 auto;
        font-weight: 700;

    }

    a {

        color: inherit;

    }

    p {

        @include paragraph( $max-width );

    }

    @include responsive-width-or-height( 760px, 760px ) {

        margin: 0 auto 20px;

    }

}

@mixin fade-down {

    animation: fade-down 1.2s ease forwards;

}

@mixin fade-down-and-out {

    animation: fade-down-and-out 1.2s ease forwards;

}

@mixin fade-up {

    animation: fade-up .6s ease forwards;

}

@mixin fade-up-and-out {

    animation: fade-up-and-out .6s ease forwards;

}

@mixin fade-out {

    animation: fade-out 1.2s ease forwards;

}

@mixin gradient-line {

    background: linear-gradient( 90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100% );

}

@mixin gradient-mask {

    -webkit-mask-image: -webkit-linear-gradient( 0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 65%, rgba(255,255,255,0) 100% );

}

@mixin highlight {

    @include gradient-mask;

    background: $color-black;

}

@mixin paragraph( $max-width: 650px ) {

    max-width: $max-width;
    margin: $line-height auto;
    font-size: $font-size;
    line-height: $line-height;

    @include text-narrow;

}

@mixin page {

    @include overlay;
    @include fade-up;
    @include no-select;

    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;

    h2 {

        @include title-large;

    }

    h3 {

        @include title-mid;

    }

}

@mixin pulse {

    animation: pulse 1s ease infinite;

}

@mixin pulse-scale {

    animation: pulse-scale 1s ease infinite;

}

@mixin text {

    p {

        text-align: left;

    }

}

@mixin text-narrow {

    @include responsive-width-or-height( 760px, 760px ) {

        &,
        p {

            font-size: $font-size-narrow !important;
            line-height: $line-height-narrow !important;

        }

    }

}

@mixin title-large {

    position: relative;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-white;
    line-height: 40px;

    @include responsive-width-or-height( 760px, 760px ) {

        font-size: 28px;
        line-height: 28px;

    }

}

@mixin title-mid {

    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: $color-white;

    span {

        font-weight: 700;

    }

    @include responsive-width-or-height( 760px, 760px ) {

        font-size: 22px;
        line-height: 26px;

    }

}