@import "../../../Import/SCSS/classes";

.Header {

    position: absolute;
    top: 0;
    right: 0;
    padding: 30px;
    transform: translateY(-100%);
    transition: transform .5s ease;
    z-index: 1000;

    .Logo,
    .LogoText {

        float: right;

    }

    &.Show {

        transform: translateY(0%);

    }

    @include responsive-width-or-height( 760px, 760px ) {

        display: none;
        left: 0;
        padding: 20px;
        text-align: center;

        .Logo,
        .LogoText {

            float: none;
            margin: 0 auto;

        }

    }

}