
@import "../../../Import/SCSS/classes";

.Button {

    @include no-select;

    $size: 130px;

    display: inline-block;
    position: relative;
    width: $size;
    height: ( $size + 4px );
    margin: 0 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: $size;
    text-align: center;
    text-transform: uppercase;
    background: $color-black;
    border: 2px solid $color-white;
    border-radius: $size;
    cursor: pointer;
    transform: scale( 1.02 );
    transition: all $duration-button ease;
    box-sizing: border-box;

    .ButtonContent {

        display: block;
        position: relative;
        border-radius: $size;
        z-index: 1;
        transition: all $duration-button ease;


    }

    .Icon {

        display: inline-block;
        width: 60px;
        height: 60px;
        vertical-align: middle;

        svg {

            position: relative;
            top: -15px;

        }

        & + span {

            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            font-size: 14px;
            line-height: 14px;

        }

    }

    &.Selected {

        transform: scale( 1.06 );

    }

    &.Active {

        transform: scale( .95 );

    }

    &.Disabled {

        transform: scale( 1 ) !important;
        opacity: .5;

    }

    &.Black,
    &.Slant {

        $border-radius: 5px;
        $padding: 10px;
        $inset: $size - 2 * $padding;

        width: $inset;
        height: ( $inset + 4px );
        padding: $padding;
        line-height: $inset;
        background: $color-black;
        border-radius: $border-radius;
        box-sizing: content-box;

        .ButtonContent {

            border: none;
            border-radius: $border-radius - 1px;

        }

    }

    &.Red {

        background: $color-red;

    }

    &.Slant {

        z-index: 100;
        transform: scale( 1.02 ) rotate( 45deg );

        &.Selected {

            transform: scale( 1.06 ) rotate( 45deg );

        }

        &.Active {

            transform: scale( .95 ) rotate( 45deg );

        }

        &.Disabled {

            transform: scale( 1 ) rotate( 45deg ) !important;

        }

        .ButtonContent {

            padding: 5px;

        }

        .Icon {

            display: block;
            width: 100%;
            height: 100%;
            transform: rotate(-45deg);

            svg {

                top: 0;

            }

        }

    }

}