
// Trafikverket
$color-black: #000000;
$color-dark: #363534;
$color-white: #ffffff;

$color-red: #D70000;
$color-red-light: #D52B1E;

// Old
// Change to red.
$color-blue: #D70000;
$color-blue-dark: #AF0000;
$color-gray: #444444;
$color-gray-dark: #222222;
$color-green: #3af03a;
$color-green-dark: #36a736;
$color-green-light: #00ff00;//#7ef37e;
// Change to black.
$color-purple: #000000;
$color-magenta: #f03a3a;
$color-magenta-dark: #ce2a2a;
$color-magenta-light: #ff0000;//#f8a6a6;

$color-opacity: rgba( 255, 255, 255, .3 );
$color-opacity-dark: rgba( 0, 0, 0, .5 );
$color-opacity-light: rgba( 0, 0, 0, .084 );

$duration-button: .2s;
$duration-button-double: $duration-button * 2;
$duration-normal: .5s;

$margin-normal: 20px;

$font-size: 24px;
$font-size-narrow: 20px;
$line-height: 30px;
$line-height-narrow: 24px;

$h1: 600px;
$w1: 1000px;

$timer-height: 100px;
