
@import "../../../Import/SCSS/classes";

$i: 40px;
$p: 10px;
$d: $i + 2 * $p;

.Stories {

    .YoutubeContainer {

        &.Full {

            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            padding: $d $d + 40px;
            background-color: $color-opacity-dark;

            .StoriesClose {

                display: block;

            }

        }

    }

}

.StoriesClose {

    display: none;
    position: absolute;
    top: $p;
    right: $p;
    width: $i;
    height: $i;
    cursor: pointer;

}

.StoriesContent {

    @include content;

}

.StoriesItem {

    display: inline-block;
    position: relative;
    width: 50%;
    margin: 0 0 20px;
    padding: 10px;
    vertical-align: middle;
    box-sizing: border-box;

    @include responsive-width( 600px ) {

        display: block;
        width: auto;

    }

}

.StoriesItemBig {

    margin: 0 0 40px;
    padding: 10px;

}

.StoriesItemTitle {

    position: relative;
    margin: 0 0 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;

}

.StoriesItems {

    position: relative;
    max-width: 800px;
    margin: 0 auto 40px;
    padding: 10px 0;
    
}