
@import "../../../Import/SCSS/classes";

$size: 40px;

.Mute {

    position: absolute;
    bottom: 20px;
    left: 20px;
    width: $size;
    height: $size;
    transition: transform .5s ease;
    cursor: pointer;

    svg {

        stroke-width: 1;

    }

    .Icon {

        transition: all $duration-button ease;

    }

    &.Lift {

        transform: translateY(-60px);

    }

    @include hover {

        .Icon {

            transform: scale( 1.06 );

        }

    }

    @include active {

        .Icon {

            transform: scale( 1 );

        }

    }

}