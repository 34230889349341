
@import "../../../Import/SCSS/classes";

.Start {

    text-align: center;

}

.StartContent {

    @include content;

}

.StartItem {

    @include no-select;

    display: inline-block;
    position: relative;
    width: 180px;
    margin: 0 30px 80px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all $duration-button ease;

    @include hover {

        transform: scale( 1.06 );

    }

    @include active {

        transform: scale( 1 );

    }

    @include responsive-width( 840px ) {

        width: 100px;
        font-size: 16px;
        line-height: 20px;

    }

    @include responsive-width( 680px ) {

        width: 80px;
        margin: 0 20px 60px;
        font-size: 14px;
        line-height: 18px;

    }

}

.StartItemIcon {

    position: relative;

    svg {

        stroke-width: 1;

    }

}

.StartItemLabel {

    position: absolute;
    top: 105%;
    left: 50%;
    width: 120%;
    transform: translate(-50%);

}

.StartItems {

    position: relative;
    max-width: 960px;
    margin: 0 auto;

    @include responsive-width( 840px ) {

        max-width: 700px;

    }

}