
@import "vars";

@keyframes fade-down {

    0% {

        opacity: 0;
        transform: translateY(-20px);

    }

    100% {

        opacity: 1;
        transform: translateY(0px);

    }

}

@keyframes fade-down-and-out {

    0% {

        opacity: 1;
        transform: translateY(0px);

    }

    100% {

        opacity: 0;
        transform: translateY(20px);

    }

}

@keyframes fade-forward-inout {

    0% {

        opacity: 0;
        transform: translateZ(500px);

    }

    50% {

        opacity: 1;
        transform: translateZ(0px);

    }

    100% {

        opacity: 0;
        transform: translateZ(-500px);

    }

}

@keyframes fade-up {

    0% {

        opacity: 0;
        transform: translateY(20px);

    }

    100% {

        opacity: 1;
        transform: translateY(0px);

    }

}

@keyframes fade-up-and-out {

    0% {

        opacity: 1;
        transform: translateY(0px);

    }

    100% {

        opacity: 0;
        transform: translateY(-20px);

    }

}

@keyframes fade-out {

    0% {

        opacity: 1;

    }

    100% {

        opacity: 0;

    }

}

@keyframes pulse {

    0% {

        background-color: $color-blue;

    }

    30% {

        background-color: $color-blue-dark;

    }

    50% {

        background-color: $color-blue;

    }

    70% {

        background-color: $color-blue-dark;

    }

    100% {

        background-color: $color-blue;

    }

}

@keyframes highlight {

    0% {
		
		background-position: 50% 0%;
		
	}
	
	50% {
		
		background-position: 50% 100%;
		
	}
	
	100% {
		
		background-position: 50% 0%;
		
	}

}

$scale: 1.02;

@keyframes pulse-scale {

    0% {

        transform: scale(#{$scale});

    }

    30% {

        transform: scale(1);

    }

    50% {

        transform: scale(#{$scale});

    }

    70% {

        transform: scale(1);

    }

    100% {

        transform: scale(#{$scale});

    }

}