
@import "../../../Import/SCSS/classes";

.Youtube {

    @include no-select;

    display: block;
    position: relative;
    padding: 0 0 ( ( 9 / 16 ) * 100% );
    background: $color-dark;

}

.YoutubePlay {

    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%,-50%);
    transition: transform .3s ease;

}

.YoutubePlayIcon {

    position: relative;
    width: 85px;
    margin: 0 auto;

}

.YoutubePlayLabel {

    white-space: nowrap;

}

.YoutubePlayer {

    @include overlay;

    iframe {

        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .5s ease;

        .YoutubeContainer.Ready & {

            opacity: 1;

        }

    }

    & > div {

        @include overlay;

    }

}

.YoutubePlayerContainer {

    position: relative;

}

.YoutubePoster {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    cursor: pointer;
    transition: opacity .5s ease;

    .LoadImage {

        $blur: 10px;
        $bleed: $blur * 2;

        @include overlay( -$bleed );

        filter: blur( $blur );

        &:after {

            @include overlay;

            display: block;
            background-color: $color-opacity-dark;
            content: "";

        }

    }

    .YoutubeContainer.Show & {

        opacity: 0;

    }

    @include hover {

        .YoutubePlay {

            transform: translate(-50%,-50%) scale( 1.1 );

        }

    }

    @include active {

        .YoutubePlay {

            transform: translate(-50%,-50%) scale( 1 );

        }

    }

}