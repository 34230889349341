
@import "../../../Import/SCSS/classes";

.Menu {

    @include overlay;

    .Intro {

        @include content;

        margin-bottom: 60px;

    }

}