
.Icon {

    svg {

        display: block;
        width: 100%;
        height: 100%;

    }

}