
@import "../../../Import/SCSS/classes";

@include responsive-width( 670px ) {

    .Intro br {

        display: none;

    }

}