@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
.About {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: fade-up .6s ease forwards;
          animation: fade-up .6s ease forwards;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box; }
  .About h2 {
    position: relative;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 40px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .About h2 {
        font-size: 28px;
        line-height: 28px; } }
  .About h3 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff; }
    .About h3 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .About h3 {
        font-size: 22px;
        line-height: 26px; } }

.AboutContent {
  margin: 0 auto 100px;
  text-align: center; }
  .AboutContent h2 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    max-width: 650px;
    margin: 0 auto;
    font-weight: 700; }
    .AboutContent h2 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .AboutContent h2 {
        font-size: 22px;
        line-height: 26px; } }
  .AboutContent a {
    color: inherit; }
  .AboutContent p {
    max-width: 650px;
    margin: 30px auto;
    font-size: 24px;
    line-height: 30px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .AboutContent p,
      .AboutContent p p {
        font-size: 20px !important;
        line-height: 24px !important; } }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .AboutContent {
      margin: 0 auto 20px; } }

.Center {
  position: relative;
  height: 100%;
  padding: 120px 20px;
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  @media screen and (max-width: 760px) {
    .Center {
      padding: 100px 20px; } }

.CenterAlign {
  display: table-cell;
  position: relative;
  vertical-align: middle; }

.CenterContainer {
  display: table;
  position: relative;
  width: 100%;
  height: 100%; }

.Navigation {
  -webkit-animation: fade-up .6s ease forwards;
          animation: fade-up .6s ease forwards;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  text-align: center; }

.NavigationItem {
  position: relative;
  max-width: 600px;
  margin: 20px auto;
  font-weight: 700;
  line-height: 100px;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 20px; }
  .NavigationItem span {
    display: inline-block;
    position: relative;
    transition: color 0.2s ease, -webkit-transform 0.4s ease;
    transition: color 0.2s ease, transform 0.4s ease;
    transition: color 0.2s ease, transform 0.4s ease, -webkit-transform 0.4s ease; }
  .NavigationItem:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 40%, white 60%, rgba(255, 255, 255, 0) 100%);
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease;
    content: ""; }
  .NavigationItem:hover {
    color: #D70000;
    background-color: #ffffff; }

.Admin {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px;
  z-index: 10000;
  overflow: hidden; }

.AdminMenu {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  transition: all .2s ease;
  opacity: 0; }
  .AdminMenu .Icon {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    margin: 5px;
    vertical-align: middle;
    opacity: .8;
    cursor: pointer; }
    .AdminMenu .Icon svg {
      stroke-width: 1.5; }
    .AdminMenu .Icon:hover {
      opacity: 1; }
  .AdminMenu:hover {
    opacity: 1; }

.Icon svg {
  display: block;
  width: 100%;
  height: 100%; }

@-webkit-keyframes bang {
  to {
    box-shadow: 130px -128.33333px deepskyblue, 463px 39.66667px #26ff00, -374px -166.33333px #ff9d00, -488px -678.33333px #ae00ff, 499px -242.33333px #8cff00, 475px -103.33333px #005eff, -358px -366.33333px #51ff00, -252px 126.66667px #6aff00, 96px -403.33333px #1eff00, 182px 14.66667px #d9ff00, 303px -217.33333px #00ff04, -148px -385.33333px #00e1ff, -482px 31.66667px #b7ff00, 435px -451.33333px #00eaff, -181px -205.33333px #0026ff, 40px -538.33333px #0080ff, -456px -506.33333px #00ffea, -316px -115.33333px #ff9500, -408px -655.33333px #e1ff00, -312px -7.33333px #00f7ff, -35px 128.66667px #66ff00, 238px 63.66667px #00ff55, -31px -326.33333px #ff006a, -288px -107.33333px #b300ff, -312px -598.33333px #ff00b7, -150px -98.33333px #ffa200, -357px -497.33333px #008cff, 85px 137.66667px #fff200, -328px -657.33333px #ff8800, -229px -804.33333px #ff1e00, -139px -286.33333px #001eff, -344px -253.33333px #0009ff, -13px -33.33333px #51ff00, 248px -139.33333px #e1ff00, -456px -782.33333px #ff0095, -262px 35.66667px #ff002b, 50px -728.33333px #00e6ff, -174px -710.33333px #c800ff, -195px -568.33333px #6600ff, 423px -172.33333px #0040ff, 292px -771.33333px #0048ff, -118px -623.33333px #ee00ff, 26px 41.66667px #ff0011, 326px -321.33333px #00ff4d, 361px 44.66667px #00ffae, 56px -99.33333px #ff00f2, 462px -647.33333px #ffa200, 416px -305.33333px #0022ff, 383px -263.33333px #ffe100, 256px -529.33333px #aaff00; } }

@keyframes bang {
  to {
    box-shadow: 130px -128.33333px deepskyblue, 463px 39.66667px #26ff00, -374px -166.33333px #ff9d00, -488px -678.33333px #ae00ff, 499px -242.33333px #8cff00, 475px -103.33333px #005eff, -358px -366.33333px #51ff00, -252px 126.66667px #6aff00, 96px -403.33333px #1eff00, 182px 14.66667px #d9ff00, 303px -217.33333px #00ff04, -148px -385.33333px #00e1ff, -482px 31.66667px #b7ff00, 435px -451.33333px #00eaff, -181px -205.33333px #0026ff, 40px -538.33333px #0080ff, -456px -506.33333px #00ffea, -316px -115.33333px #ff9500, -408px -655.33333px #e1ff00, -312px -7.33333px #00f7ff, -35px 128.66667px #66ff00, 238px 63.66667px #00ff55, -31px -326.33333px #ff006a, -288px -107.33333px #b300ff, -312px -598.33333px #ff00b7, -150px -98.33333px #ffa200, -357px -497.33333px #008cff, 85px 137.66667px #fff200, -328px -657.33333px #ff8800, -229px -804.33333px #ff1e00, -139px -286.33333px #001eff, -344px -253.33333px #0009ff, -13px -33.33333px #51ff00, 248px -139.33333px #e1ff00, -456px -782.33333px #ff0095, -262px 35.66667px #ff002b, 50px -728.33333px #00e6ff, -174px -710.33333px #c800ff, -195px -568.33333px #6600ff, 423px -172.33333px #0040ff, 292px -771.33333px #0048ff, -118px -623.33333px #ee00ff, 26px 41.66667px #ff0011, 326px -321.33333px #00ff4d, 361px 44.66667px #00ffae, 56px -99.33333px #ff00f2, 462px -647.33333px #ffa200, 416px -305.33333px #0022ff, 383px -263.33333px #ffe100, 256px -529.33333px #aaff00; } }

@-webkit-keyframes gravity {
  to {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px); } }

@keyframes gravity {
  to {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px); } }

@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

.Game {
  position: relative;
  height: 100%;
  overflow: hidden; }

.GameCounter {
  position: absolute !important;
  right: 20px;
  bottom: 20px; }

.GameDone,
.GameQuestion,
.GameStart {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: fade-up .6s ease forwards;
          animation: fade-up .6s ease forwards;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box; }
  .GameDone h2,
  .GameQuestion h2,
  .GameStart h2 {
    position: relative;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 40px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .GameDone h2,
      .GameQuestion h2,
      .GameStart h2 {
        font-size: 28px;
        line-height: 28px; } }
  .GameDone h3,
  .GameQuestion h3,
  .GameStart h3 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff; }
    .GameDone h3 span,
    .GameQuestion h3 span,
    .GameStart h3 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .GameDone h3,
      .GameQuestion h3,
      .GameStart h3 {
        font-size: 22px;
        line-height: 26px; } }

.GameDoneError {
  position: relative; }
  .GameDoneError h3 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    max-width: 500px;
    margin: 36px auto; }
    .GameDoneError h3 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .GameDoneError h3 {
        font-size: 22px;
        line-height: 26px; } }
  .GameDoneError p {
    max-width: 500px;
    margin: 30px auto; }
  .GameDoneError :first-child {
    margin-top: 0 !important; }
  .GameDoneError :last-child {
    margin-bottom: 0 !important; }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .GameDoneError,
    .GameDoneError p {
      font-size: 20px !important;
      line-height: 24px !important; } }

.GameDoneNoErrors {
  margin: 0 auto 100px;
  text-align: center; }
  .GameDoneNoErrors h2 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    max-width: 650px;
    margin: 0 auto;
    font-weight: 700; }
    .GameDoneNoErrors h2 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .GameDoneNoErrors h2 {
        font-size: 22px;
        line-height: 26px; } }
  .GameDoneNoErrors a {
    color: inherit; }
  .GameDoneNoErrors p {
    max-width: 650px;
    margin: 30px auto;
    font-size: 24px;
    line-height: 30px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .GameDoneNoErrors p,
      .GameDoneNoErrors p p {
        font-size: 20px !important;
        line-height: 24px !important; } }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .GameDoneNoErrors {
      margin: 0 auto 20px; } }
  .GameDoneNoErrors > p:first-child {
    max-width: 500px; }
  .WebVersion .GameDoneNoErrors {
    margin-bottom: 0; }

.GameDoneShare {
  position: relative;
  margin: 0; }
  .GameDoneShare .ShareButton {
    margin: 20px; }

.GameDoneSource {
  font-size: 14px;
  line-height: 18px;
  position: relative;
  top: -20px; }
  .GameDoneSource a {
    color: inherit; }

.GameDoneHighscore {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: fade-up .6s ease forwards;
          animation: fade-up .6s ease forwards;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden; }
  .GameDoneHighscore h2 {
    position: relative;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 40px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .GameDoneHighscore h2 {
        font-size: 28px;
        line-height: 28px; } }
  .GameDoneHighscore h3 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff; }
    .GameDoneHighscore h3 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .GameDoneHighscore h3 {
        font-size: 22px;
        line-height: 26px; } }
  .GameDoneHighscore h2 {
    padding: 0 0 60px; }
  .GameDoneHighscore.Fireworks {
    overflow: hidden; }
    .GameDoneHighscore.Fireworks:before, .GameDoneHighscore.Fireworks:after {
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff;
      background: #D70000;
      -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
              animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
      content: "";
      z-index: 1000; }
    .GameDoneHighscore.Fireworks:after {
      -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
              animation-delay: 1.25s, 1.25s, 1.25s;
      -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
              animation-duration: 1.25s, 1.25s, 6.25s; }

.GameDoneHighscoreItem {
  position: absolute;
  right: 0;
  left: 0;
  font-weight: 700;
  text-align: left;
  transition: all 0.5s ease;
  -webkit-animation: fade-up .5s ease;
          animation: fade-up .5s ease; }
  .GameDoneHighscoreItem.Highlight {
    -webkit-mask-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 35%, white 65%, rgba(255, 255, 255, 0) 100%);
    background: #000000; }
  .GameDoneHighscoreItem.Live {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 40%, white 60%, rgba(255, 255, 255, 0) 100%);
    color: #000000;
    z-index: 10; }
    .GameDoneHighscoreItem.Live:after {
      display: none; }
  .GameDoneHighscoreItem.Out {
    -webkit-animation: fade-down-and-out .5s ease forwards;
            animation: fade-down-and-out .5s ease forwards; }
  .GameDoneHighscoreItem:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 40%, white 60%, rgba(255, 255, 255, 0) 100%);
    display: block;
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 2px;
    content: ""; }

.GameDoneHighscoreItemContent {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 50px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.GameDoneHighscorePlace {
  display: inline-block;
  position: relative;
  width: 50px; }

.GameDoneHighscorePoints {
  position: absolute !important;
  top: calc( 50% - 12px);
  right: 0; }

.GameDoneHighscoreWrapper {
  position: relative;
  transition: all 0.5s ease; }

.GameErrorCounter {
  position: absolute !important;
  bottom: 17px;
  left: 17px; }

.GameStartRules {
  margin: 0 auto 100px;
  text-align: center; }
  .GameStartRules h2 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    max-width: 650px;
    margin: 0 auto;
    font-weight: 700; }
    .GameStartRules h2 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .GameStartRules h2 {
        font-size: 22px;
        line-height: 26px; } }
  .GameStartRules a {
    color: inherit; }
  .GameStartRules p {
    max-width: 650px;
    margin: 30px auto;
    font-size: 24px;
    line-height: 30px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .GameStartRules p,
      .GameStartRules p p {
        font-size: 20px !important;
        line-height: 24px !important; } }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .GameStartRules {
      margin: 0 auto 20px; } }

.GameStartRulesText p {
  text-align: left; }

.GameQuestion h3 {
  max-width: 500px;
  margin: 36px auto !important; }

.GameQuestion.Answered {
  -webkit-animation: fade-up-and-out .6s ease forwards;
          animation: fade-up-and-out .6s ease forwards; }

.GameQuestion.Pulse:before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  -webkit-animation: pulse 1s ease infinite;
          animation: pulse 1s ease infinite; }

.GameQuestion.Pulse .CenterContent {
  -webkit-animation: pulse-scale 1s ease infinite;
          animation: pulse-scale 1s ease infinite; }

.GameQuestionText {
  position: relative; }

.ReadySetGo {
  position: relative;
  -webkit-perspective: 500px;
          perspective: 500px;
  width: 300px;
  height: 100px;
  margin: 0 auto; }
  .ReadySetGo div {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    line-height: 100px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    opacity: 0; }
  .ReadySetGo.Ready .ReadySetGoReady,
  .ReadySetGo.Set .ReadySetGoSet,
  .ReadySetGo.Go .ReadySetGoGo {
    -webkit-animation: fade-forward-inout .75s linear;
            animation: fade-forward-inout .75s linear; }

.ReadySetGoContainer {
  overflow: hidden; }

.Counter {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  position: relative;
  white-space: nowrap;
  z-index: 1; }

.CounterChar {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .CounterChar span {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center; }
    .CounterChar span.Prev {
      -webkit-animation: char-up-and-out .2s ease forwards;
              animation: char-up-and-out .2s ease forwards; }
    .CounterChar span.Next {
      -webkit-animation: char-up .2s ease forwards;
              animation: char-up .2s ease forwards; }

@-webkit-keyframes char-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@keyframes char-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes char-up-and-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@keyframes char-up-and-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

.Buttons {
  margin: 42px 0 0; }

@media screen and (max-width: 760px), screen and (max-height: 760px) {
  .Button {
    margin: 0 !important; }
  .Buttons {
    margin: 20px 0 0; }
  .ButtonContainer {
    display: inline-block;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

.Button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  display: inline-block;
  position: relative;
  width: 130px;
  height: 134px;
  margin: 0 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 130px;
  text-align: center;
  text-transform: uppercase;
  background: #000000;
  border: 2px solid #ffffff;
  border-radius: 130px;
  cursor: pointer;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: all 0.2s ease;
  box-sizing: border-box; }
  .Button .ButtonContent {
    display: block;
    position: relative;
    border-radius: 130px;
    z-index: 1;
    transition: all 0.2s ease; }
  .Button .Icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    vertical-align: middle; }
    .Button .Icon svg {
      position: relative;
      top: -15px; }
    .Button .Icon + span {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      font-size: 14px;
      line-height: 14px; }
  .Button.Selected {
    -webkit-transform: scale(1.06);
            transform: scale(1.06); }
  .Button.Active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  .Button.Disabled {
    -webkit-transform: scale(1) !important;
            transform: scale(1) !important;
    opacity: .5; }
  .Button.Black, .Button.Slant {
    width: 110px;
    height: 114px;
    padding: 10px;
    line-height: 110px;
    background: #000000;
    border-radius: 5px;
    box-sizing: content-box; }
    .Button.Black .ButtonContent, .Button.Slant .ButtonContent {
      border: none;
      border-radius: 4px; }
  .Button.Red {
    background: #D70000; }
  .Button.Slant {
    z-index: 100;
    -webkit-transform: scale(1.02) rotate(45deg);
            transform: scale(1.02) rotate(45deg); }
    .Button.Slant.Selected {
      -webkit-transform: scale(1.06) rotate(45deg);
              transform: scale(1.06) rotate(45deg); }
    .Button.Slant.Active {
      -webkit-transform: scale(0.95) rotate(45deg);
              transform: scale(0.95) rotate(45deg); }
    .Button.Slant.Disabled {
      -webkit-transform: scale(1) rotate(45deg) !important;
              transform: scale(1) rotate(45deg) !important; }
    .Button.Slant .ButtonContent {
      padding: 5px; }
    .Button.Slant .Icon {
      display: block;
      width: 100%;
      height: 100%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
      .Button.Slant .Icon svg {
        top: 0; }

.ShareButton {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  color: #ffffff;
  line-height: 40px;
  transition: all 0.2s ease; }
  .ShareButton .Icon svg {
    stroke-width: 1; }
  .ShareButton:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .ShareButton:active {
    -webkit-transform: scale(1);
            transform: scale(1); }

.Slide {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  transition: all 0.5s ease;
  z-index: 10; }
  .Slide.After {
    -webkit-transform: translate(20px, -50%);
            transform: translate(20px, -50%);
    opacity: 0;
    z-index: 1; }
  .Slide.Before {
    -webkit-transform: translate(-20px, -50%);
            transform: translate(-20px, -50%);
    opacity: 0;
    z-index: 1; }

.Slider {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  padding: 15px 0 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0) 100%);
  transition: height 0.5s ease; }

.SliderButtons {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -67px 0 0; }
  .SliderButtons .Button {
    display: block; }
  .SliderButtons .ButtonContainer {
    display: block;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    float: left; }
    .SliderButtons .ButtonContainer:last-child {
      float: right; }

.Slides {
  position: relative;
  height: 100%;
  margin: 0 140px; }

@media screen and (max-width: 1024px) {
  .Slider {
    margin-bottom: 130px; }
    .WebVersion .Slider {
      margin-bottom: 100px; }
  .SliderButtons {
    top: 100%;
    margin-top: -50px; }
  .Slides {
    margin: 0; } }

@media screen and (max-width: 760px), screen and (max-height: 760px) {
  .Slider {
    margin-bottom: 200px; }
  .SliderButtons {
    top: 100%;
    margin-top: 0; } }

.Menu {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .Menu .Intro {
    margin: 0 auto 100px;
    text-align: center;
    margin-bottom: 60px; }
    .Menu .Intro h2 {
      position: relative;
      margin: 36px 0;
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      color: #ffffff;
      max-width: 650px;
      margin: 0 auto;
      font-weight: 700; }
      .Menu .Intro h2 span {
        font-weight: 700; }
      @media screen and (max-width: 760px), screen and (max-height: 760px) {
        .Menu .Intro h2 {
          font-size: 22px;
          line-height: 26px; } }
    .Menu .Intro a {
      color: inherit; }
    .Menu .Intro p {
      max-width: 650px;
      margin: 30px auto;
      font-size: 24px;
      line-height: 30px; }
      @media screen and (max-width: 760px), screen and (max-height: 760px) {
        .Menu .Intro p,
        .Menu .Intro p p {
          font-size: 20px !important;
          line-height: 24px !important; } }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .Menu .Intro {
        margin: 0 auto 20px; } }

@media screen and (max-width: 670px) {
  .Intro br {
    display: none; } }

.ErrorCounter {
  position: relative;
  margin: -5px 0 0 -5px;
  white-space: nowrap;
  z-index: 1; }
  .ErrorCounter .Dot {
    display: inline-block;
    position: relative;
    width: 26px;
    height: 26px;
    margin: 5px;
    border-radius: 26px;
    background: #AF0000; }
    .ErrorCounter .Dot:after {
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      border-radius: 26px;
      background: #000000;
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
      transition: all .5s ease;
      content: ""; }
    .ErrorCounter .Dot.Filled:after {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }
    .ErrorCounter .Dot .Icon {
      position: absolute !important;
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      z-index: 1; }

.Register {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: fade-up .6s ease forwards;
          animation: fade-up .6s ease forwards;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box; }
  .Register h2 {
    position: relative;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 40px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .Register h2 {
        font-size: 28px;
        line-height: 28px; } }
  .Register h3 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff; }
    .Register h3 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .Register h3 {
        font-size: 22px;
        line-height: 26px; } }

.RegisterContent {
  margin: 0 auto 100px;
  text-align: center; }
  .RegisterContent h2 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    max-width: 650px;
    margin: 0 auto;
    font-weight: 700; }
    .RegisterContent h2 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .RegisterContent h2 {
        font-size: 22px;
        line-height: 26px; } }
  .RegisterContent a {
    color: inherit; }
  .RegisterContent p {
    max-width: 650px;
    margin: 30px auto;
    font-size: 24px;
    line-height: 30px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .RegisterContent p,
      .RegisterContent p p {
        font-size: 20px !important;
        line-height: 24px !important; } }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .RegisterContent {
      margin: 0 auto 20px; } }
  .RegisterContent p {
    max-width: 600px;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: left; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .RegisterContent p {
        margin: 20px auto;
        font-size: 16px !important;
        line-height: 20px !important; } }

.Checkbox {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  position: relative;
  max-width: 600px;
  margin: 40px auto;
  text-align: left;
  line-height: 28px; }
  .Checkbox p {
    position: relative;
    overflow: hidden;
    font-size: 20px;
    line-height: 26px; }
  .Checkbox .Icon {
    display: block;
    top: 1px;
    width: 100%;
    height: 100%;
    padding: 2px;
    box-sizing: border-box;
    color: #D70000; }
    .Checkbox .Icon svg {
      display: block;
      width: 100%;
      height: 100%;
      stroke-width: 4; }
  .Checkbox .Input {
    display: block;
    position: relative;
    float: left;
    width: 24px;
    height: 24px;
    margin: 2px 15px 2px 0;
    background-color: currentColor;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    -webkit-animation: scale-in 1s ease;
            animation: scale-in 1s ease;
    transition: all .2s ease; }
    .Checkbox .Input:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .Checkbox .Input:active {
      -webkit-transform: scale(1);
              transform: scale(1); }
    .Checkbox .Input.Disabled {
      opacity: .5;
      -webkit-transform: none !important;
              transform: none !important; }

.ScreenField {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: fade-up .6s ease forwards;
          animation: fade-up .6s ease forwards;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box; }
  .ScreenField h2 {
    position: relative;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 40px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .ScreenField h2 {
        font-size: 28px;
        line-height: 28px; } }
  .ScreenField h3 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff; }
    .ScreenField h3 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .ScreenField h3 {
        font-size: 22px;
        line-height: 26px; } }
  .ScreenField .Input {
    display: block;
    position: relative;
    width: 100%;
    height: 78px;
    max-width: 600px;
    margin: 20px auto;
    color: currentColor;
    background: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    font-size: 24px;
    font-weight: 400;
    border-bottom: 2px solid currentColor;
    box-sizing: border-box; }
    .ScreenField .Input::-webkit-input-placeholder {
      color: currentColor; }
    .ScreenField .Input:-moz-placeholder {
      color: currentColor; }
    .ScreenField .Input::-moz-placeholder {
      color: currentColor; }
    .ScreenField .Input:-ms-input-placeholder {
      color: currentColor; }

.ScreenFieldContent {
  margin: 0 auto 100px;
  text-align: center; }
  .ScreenFieldContent h2 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    max-width: 650px;
    margin: 0 auto;
    font-weight: 700; }
    .ScreenFieldContent h2 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .ScreenFieldContent h2 {
        font-size: 22px;
        line-height: 26px; } }
  .ScreenFieldContent a {
    color: inherit; }
  .ScreenFieldContent p {
    max-width: 650px;
    margin: 30px auto;
    font-size: 24px;
    line-height: 30px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .ScreenFieldContent p,
      .ScreenFieldContent p p {
        font-size: 20px !important;
        line-height: 24px !important; } }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .ScreenFieldContent {
      margin: 0 auto 20px; } }

.ScreenTerms {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: fade-up .6s ease forwards;
          animation: fade-up .6s ease forwards;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box; }
  .ScreenTerms h2 {
    position: relative;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 40px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .ScreenTerms h2 {
        font-size: 28px;
        line-height: 28px; } }
  .ScreenTerms h3 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff; }
    .ScreenTerms h3 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .ScreenTerms h3 {
        font-size: 22px;
        line-height: 26px; } }
  .ScreenTerms .ScreenTermsContent {
    margin: 0 auto 100px;
    text-align: center; }
    .ScreenTerms .ScreenTermsContent h2 {
      position: relative;
      margin: 36px 0;
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      color: #ffffff;
      max-width: 650px;
      margin: 0 auto;
      font-weight: 700; }
      .ScreenTerms .ScreenTermsContent h2 span {
        font-weight: 700; }
      @media screen and (max-width: 760px), screen and (max-height: 760px) {
        .ScreenTerms .ScreenTermsContent h2 {
          font-size: 22px;
          line-height: 26px; } }
    .ScreenTerms .ScreenTermsContent a {
      color: inherit; }
    .ScreenTerms .ScreenTermsContent p {
      max-width: 650px;
      margin: 30px auto;
      font-size: 24px;
      line-height: 30px; }
      @media screen and (max-width: 760px), screen and (max-height: 760px) {
        .ScreenTerms .ScreenTermsContent p,
        .ScreenTerms .ScreenTermsContent p p {
          font-size: 20px !important;
          line-height: 24px !important; } }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .ScreenTerms .ScreenTermsContent {
        margin: 0 auto 20px; } }

.TextField {
  position: relative; }
  .TextField .Input {
    display: block;
    position: relative;
    width: 100%;
    height: 78px;
    max-width: 600px;
    margin: 20px auto;
    color: currentColor;
    background: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    font-size: 24px;
    font-weight: 400;
    border-bottom: 2px solid currentColor;
    box-sizing: border-box; }
    .TextField .Input::-webkit-input-placeholder {
      color: currentColor; }
    .TextField .Input:-moz-placeholder {
      color: currentColor; }
    .TextField .Input::-moz-placeholder {
      color: currentColor; }
    .TextField .Input:-ms-input-placeholder {
      color: currentColor; }
  .TextField:first-child .Input {
    margin-top: 0; }
  .TextField.Disabled .Input {
    opacity: .5; }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .TextField .Input {
      height: 56px;
      font-size: 20px; } }

.Timer {
  position: relative;
  z-index: 1; }

.TimerAddition {
  position: relative;
  float: right;
  height: 3px;
  background: #ffffff;
  transition: opacity 2s ease; }
  .TimerAddition.Added {
    opacity: 0; }
  .TimerAddition.Fadeout {
    -webkit-animation: fade-out 1.2s ease forwards;
            animation: fade-out 1.2s ease forwards; }
  .TimerAddition.Penalty {
    float: left;
    background: #f03a3a;
    z-index: 20; }

.TimerAdditionLabel {
  -webkit-animation: fade-down-and-out 1.2s ease forwards;
          animation: fade-down-and-out 1.2s ease forwards;
  position: absolute;
  top: 74px;
  right: 0;
  left: 0;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  z-index: 1; }

.TimerProgress {
  position: absolute;
  top: 48.5px;
  right: 20px;
  left: 20px;
  height: 3px;
  background: #AF0000;
  border-radius: 3px;
  -webkit-mask-image: -webkit-linear-gradient(0deg, black 25%, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0) 55%, black 75%); }

.TimerProgressBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  border-radius: 3px;
  z-index: 10; }

.TimerRemaining {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  font-size: 36px;
  text-align: center;
  line-height: 100px; }

.Footer {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 30px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  z-index: 1000; }
  .Footer .Sida {
    float: right; }
  .Footer.Show {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  @media screen and (max-width: 760px) {
    .Footer {
      padding: 20px; } }

.Trafikverket {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .Trafikverket svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #ffffff; }

.Header {
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  z-index: 1000; }
  .Header .Logo,
  .Header .LogoText {
    float: right; }
  .Header.Show {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .Header {
      display: none;
      left: 0;
      padding: 20px;
      text-align: center; }
      .Header .Logo,
      .Header .LogoText {
        float: none;
        margin: 0 auto; } }

@-webkit-keyframes bang {
  to {
    box-shadow: 412px -95.33333px #ff0066, -477px -271.33333px #00ff48, 208px -299.33333px #0099ff, -23px -726.33333px #00a6ff, 403px -242.33333px #ff4800, 470px -823.33333px #c400ff, 63px -237.33333px #4000ff, -95px -548.33333px #fffb00, -313px 100.66667px #26ff00, 234px -469.33333px #55ff00, 0px -247.33333px #ff7b00, 435px -824.33333px #00ff8c, -255px 30.66667px #7bff00, -126px -630.33333px #62ff00, 300px -480.33333px #ff00bb, 115px -79.33333px #ff00a2, -421px -434.33333px #ff0080, -4px -681.33333px #51ff00, 293px 34.66667px #ff2200, 341px -645.33333px #00a6ff, -251px -195.33333px #c4ff00, -342px -594.33333px #ff0011, -29px -623.33333px #002bff, 460px 117.66667px #ff00b3, -363px -58.33333px #00ff37, 132px -221.33333px #ff1e00, -134px -742.33333px #00ff66, -278px -462.33333px #0dff00, 205px -224.33333px #c4ff00, -10px -711.33333px #0022ff, -292px -788.33333px #6200ff, 375px -583.33333px #26ff00, -257px 86.66667px #ff00f7, -98px 22.66667px #00ffc8, 64px -731.33333px #2f00ff, -185px -249.33333px #5eff00, 117px -121.33333px #ff00d9, -62px -491.33333px #ff0073, -148px -447.33333px #ff001a, 321px -1.33333px #ff5500, -487px -400.33333px #0091ff, 280px -184.33333px #ff0015, -166px -649.33333px #88ff00, 487px -798.33333px yellow, -279px -723.33333px #ff0037, -419px 30.66667px #ffbf00, -3px -692.33333px #00ff22, 89px 138.66667px #ff006a, 226px -224.33333px #00d5ff, -305px -512.33333px #00ff48; } }

@keyframes bang {
  to {
    box-shadow: 412px -95.33333px #ff0066, -477px -271.33333px #00ff48, 208px -299.33333px #0099ff, -23px -726.33333px #00a6ff, 403px -242.33333px #ff4800, 470px -823.33333px #c400ff, 63px -237.33333px #4000ff, -95px -548.33333px #fffb00, -313px 100.66667px #26ff00, 234px -469.33333px #55ff00, 0px -247.33333px #ff7b00, 435px -824.33333px #00ff8c, -255px 30.66667px #7bff00, -126px -630.33333px #62ff00, 300px -480.33333px #ff00bb, 115px -79.33333px #ff00a2, -421px -434.33333px #ff0080, -4px -681.33333px #51ff00, 293px 34.66667px #ff2200, 341px -645.33333px #00a6ff, -251px -195.33333px #c4ff00, -342px -594.33333px #ff0011, -29px -623.33333px #002bff, 460px 117.66667px #ff00b3, -363px -58.33333px #00ff37, 132px -221.33333px #ff1e00, -134px -742.33333px #00ff66, -278px -462.33333px #0dff00, 205px -224.33333px #c4ff00, -10px -711.33333px #0022ff, -292px -788.33333px #6200ff, 375px -583.33333px #26ff00, -257px 86.66667px #ff00f7, -98px 22.66667px #00ffc8, 64px -731.33333px #2f00ff, -185px -249.33333px #5eff00, 117px -121.33333px #ff00d9, -62px -491.33333px #ff0073, -148px -447.33333px #ff001a, 321px -1.33333px #ff5500, -487px -400.33333px #0091ff, 280px -184.33333px #ff0015, -166px -649.33333px #88ff00, 487px -798.33333px yellow, -279px -723.33333px #ff0037, -419px 30.66667px #ffbf00, -3px -692.33333px #00ff22, 89px 138.66667px #ff006a, 226px -224.33333px #00d5ff, -305px -512.33333px #00ff48; } }

@-webkit-keyframes gravity {
  to {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px); } }

@keyframes gravity {
  to {
    opacity: 0;
    -webkit-transform: translateY(400px);
            transform: translateY(400px); } }

@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

.Highscore {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: fade-up .6s ease forwards;
          animation: fade-up .6s ease forwards;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden; }
  .Highscore h2 {
    position: relative;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 40px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .Highscore h2 {
        font-size: 28px;
        line-height: 28px; } }
  .Highscore h3 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff; }
    .Highscore h3 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .Highscore h3 {
        font-size: 22px;
        line-height: 26px; } }
  .Highscore h2 {
    padding: 0 0 60px; }
  .Highscore.Fireworks {
    overflow: hidden; }
    .Highscore.Fireworks:before, .Highscore.Fireworks:after {
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff, 0 0 #ffffff;
      background: #D70000;
      -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
              animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
      content: "";
      z-index: 1000; }
    .Highscore.Fireworks:after {
      -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
              animation-delay: 1.25s, 1.25s, 1.25s;
      -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
              animation-duration: 1.25s, 1.25s, 6.25s; }
  .Highscore.HasNavigation {
    overflow: auto; }
  .Highscore .Navigation {
    margin-top: 20px; }

.HighscoreItem {
  position: absolute;
  right: 0;
  left: 0;
  font-weight: 700;
  text-align: left;
  transition: all 0.5s ease;
  -webkit-animation: fade-up .5s ease;
          animation: fade-up .5s ease; }
  .HighscoreItem.Highlight {
    -webkit-mask-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 35%, white 65%, rgba(255, 255, 255, 0) 100%);
    background: #000000; }
  .HighscoreItem.Live {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 40%, white 60%, rgba(255, 255, 255, 0) 100%);
    color: #000000;
    z-index: 10; }
    .HighscoreItem.Live:after {
      display: none; }
  .HighscoreItem.Out {
    -webkit-animation: fade-down-and-out .5s ease forwards;
            animation: fade-down-and-out .5s ease forwards; }
  .HighscoreItem:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 40%, white 60%, rgba(255, 255, 255, 0) 100%);
    display: block;
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 2px;
    content: ""; }

.HighscoreItemContent {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 50px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.HighscorePlace {
  display: inline-block;
  position: relative;
  width: 50px; }

.HighscorePoints {
  position: absolute;
  top: calc( 50% - 12px);
  right: 0; }

.HighscoreWrapper {
  position: relative;
  transition: all 0.5s ease; }

.Mute {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  cursor: pointer; }
  .Mute svg {
    stroke-width: 1; }
  .Mute .Icon {
    transition: all 0.2s ease; }
  .Mute.Lift {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px); }
  .Mute:hover .Icon {
    -webkit-transform: scale(1.06);
            transform: scale(1.06); }
  .Mute:active .Icon {
    -webkit-transform: scale(1);
            transform: scale(1); }

.Share {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: fade-up .6s ease forwards;
          animation: fade-up .6s ease forwards;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box; }
  .Share h2 {
    position: relative;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 40px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .Share h2 {
        font-size: 28px;
        line-height: 28px; } }
  .Share h3 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff; }
    .Share h3 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .Share h3 {
        font-size: 22px;
        line-height: 26px; } }

.ShareContent {
  margin: 0 auto 100px;
  text-align: center; }
  .ShareContent h2 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    max-width: 650px;
    margin: 0 auto;
    font-weight: 700; }
    .ShareContent h2 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .ShareContent h2 {
        font-size: 22px;
        line-height: 26px; } }
  .ShareContent a {
    color: inherit; }
  .ShareContent p {
    max-width: 650px;
    margin: 30px auto;
    font-size: 24px;
    line-height: 30px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .ShareContent p,
      .ShareContent p p {
        font-size: 20px !important;
        line-height: 24px !important; } }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .ShareContent {
      margin: 0 auto 20px; } }

.Start {
  text-align: center; }

.StartContent {
  margin: 0 auto 100px;
  text-align: center; }
  .StartContent h2 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    max-width: 650px;
    margin: 0 auto;
    font-weight: 700; }
    .StartContent h2 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .StartContent h2 {
        font-size: 22px;
        line-height: 26px; } }
  .StartContent a {
    color: inherit; }
  .StartContent p {
    max-width: 650px;
    margin: 30px auto;
    font-size: 24px;
    line-height: 30px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .StartContent p,
      .StartContent p p {
        font-size: 20px !important;
        line-height: 24px !important; } }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .StartContent {
      margin: 0 auto 20px; } }

.StartItem {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  display: inline-block;
  position: relative;
  width: 180px;
  margin: 0 30px 80px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease; }
  .StartItem:hover {
    -webkit-transform: scale(1.06);
            transform: scale(1.06); }
  .StartItem:active {
    -webkit-transform: scale(1);
            transform: scale(1); }
  @media screen and (max-width: 840px) {
    .StartItem {
      width: 100px;
      font-size: 16px;
      line-height: 20px; } }
  @media screen and (max-width: 680px) {
    .StartItem {
      width: 80px;
      margin: 0 20px 60px;
      font-size: 14px;
      line-height: 18px; } }

.StartItemIcon {
  position: relative; }
  .StartItemIcon svg {
    stroke-width: 1; }

.StartItemLabel {
  position: absolute;
  top: 105%;
  left: 50%;
  width: 120%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%); }

.StartItems {
  position: relative;
  max-width: 960px;
  margin: 0 auto; }
  @media screen and (max-width: 840px) {
    .StartItems {
      max-width: 700px; } }

.Stories .YoutubeContainer.Full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 60px 100px;
  background-color: rgba(0, 0, 0, 0.5); }
  .Stories .YoutubeContainer.Full .StoriesClose {
    display: block; }

.StoriesClose {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer; }

.StoriesContent {
  margin: 0 auto 100px;
  text-align: center; }
  .StoriesContent h2 {
    position: relative;
    margin: 36px 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    max-width: 650px;
    margin: 0 auto;
    font-weight: 700; }
    .StoriesContent h2 span {
      font-weight: 700; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .StoriesContent h2 {
        font-size: 22px;
        line-height: 26px; } }
  .StoriesContent a {
    color: inherit; }
  .StoriesContent p {
    max-width: 650px;
    margin: 30px auto;
    font-size: 24px;
    line-height: 30px; }
    @media screen and (max-width: 760px), screen and (max-height: 760px) {
      .StoriesContent p,
      .StoriesContent p p {
        font-size: 20px !important;
        line-height: 24px !important; } }
  @media screen and (max-width: 760px), screen and (max-height: 760px) {
    .StoriesContent {
      margin: 0 auto 20px; } }

.StoriesItem {
  display: inline-block;
  position: relative;
  width: 50%;
  margin: 0 0 20px;
  padding: 10px;
  vertical-align: middle;
  box-sizing: border-box; }
  @media screen and (max-width: 600px) {
    .StoriesItem {
      display: block;
      width: auto; } }

.StoriesItemBig {
  margin: 0 0 40px;
  padding: 10px; }

.StoriesItemTitle {
  position: relative;
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase; }

.StoriesItems {
  position: relative;
  max-width: 800px;
  margin: 0 auto 40px;
  padding: 10px 0; }

.Youtube {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  display: block;
  position: relative;
  padding: 0 0 56.25%;
  background: #363534; }

.YoutubePlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.YoutubePlayIcon {
  position: relative;
  width: 85px;
  margin: 0 auto; }

.YoutubePlayLabel {
  white-space: nowrap; }

.YoutubePlayer {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .YoutubePlayer iframe {
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .5s ease; }
    .YoutubeContainer.Ready .YoutubePlayer iframe {
      opacity: 1; }
  .YoutubePlayer > div {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.YoutubePlayerContainer {
  position: relative; }

.YoutubePoster {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
  transition: opacity .5s ease; }
  .YoutubePoster .LoadImage {
    position: absolute !important;
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
    -webkit-filter: blur(10px);
            filter: blur(10px); }
    .YoutubePoster .LoadImage:after {
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
      content: ""; }
  .YoutubeContainer.Show .YoutubePoster {
    opacity: 0; }
  .YoutubePoster:hover .YoutubePlay {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
            transform: translate(-50%, -50%) scale(1.1); }
  .YoutubePoster:active .YoutubePlay {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1); }

.LoadImage {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity .5s;
  background-size: cover; }
  .LoadImage.Loaded {
    opacity: 1; }

@-webkit-keyframes fade-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }
@keyframes fade-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes fade-down-and-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); } }

@keyframes fade-down-and-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); } }

@-webkit-keyframes fade-forward-inout {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(500px);
            transform: translateZ(500px); }
  50% {
    opacity: 1;
    -webkit-transform: translateZ(0px);
            transform: translateZ(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateZ(-500px);
            transform: translateZ(-500px); } }

@keyframes fade-forward-inout {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(500px);
            transform: translateZ(500px); }
  50% {
    opacity: 1;
    -webkit-transform: translateZ(0px);
            transform: translateZ(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateZ(-500px);
            transform: translateZ(-500px); } }

@-webkit-keyframes fade-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes fade-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes fade-up-and-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); } }

@keyframes fade-up-and-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); } }

@-webkit-keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes pulse {
  0% {
    background-color: #D70000; }
  30% {
    background-color: #AF0000; }
  50% {
    background-color: #D70000; }
  70% {
    background-color: #AF0000; }
  100% {
    background-color: #D70000; } }

@keyframes pulse {
  0% {
    background-color: #D70000; }
  30% {
    background-color: #AF0000; }
  50% {
    background-color: #D70000; }
  70% {
    background-color: #AF0000; }
  100% {
    background-color: #D70000; } }

@-webkit-keyframes highlight {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

@keyframes highlight {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

@-webkit-keyframes pulse-scale {
  0% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); } }

@keyframes pulse-scale {
  0% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); } }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body,
input,
textarea,
select {
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html,
body,
#root,
.Core {
  position: relative;
  height: 100%;
  width: 100%;
  background: #D70000;
  transition: background 1s ease;
  overflow: hidden; }

b,
strong {
  font-weight: 700; }

i {
  font-weight: 700; }

hr {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 40%, white 60%, rgba(255, 255, 255, 0) 100%);
  display: block;
  width: 100%;
  height: 2px;
  margin: 40px 0;
  border: none; }
  @media screen and (max-width: 1000px), screen and (max-height: 600px) {
    hr {
      height: 2px;
      margin: 20px 0; } }

p {
  margin: 30px 0; }

