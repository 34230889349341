
@import "../../../Import/SCSS/classes";

$timer-size: 3px;
$timer-padding: ( $timer-height - $timer-size ) * .5;
$timer-radius: $timer-size;

.Timer {

    position: relative;
    z-index: 1;

}

.TimerAddition {

    position: relative;
    float: right;
    height: $timer-size;
    background: $color-white;
    transition: opacity 2s ease;

    &.Added {

        opacity: 0;

    }

    &.Fadeout {

        @include fade-out;

    }

    &.Penalty {

        float: left;
        background: $color-magenta;
        z-index: 20;

    }

}

.TimerAdditionLabel {

    @include fade-down-and-out;

    position: absolute;
    top: $timer-height * .5 + 24px;
    right: 0;
    left: 0;
    color: $color-white;
    font-size: 24px;
    text-align: center;
    z-index: 1;

}

.TimerProgress {

    position: absolute;
    top: $timer-padding;
    right: $margin-normal;
    left: $margin-normal;
    height: $timer-size;
    background: $color-blue-dark;
    border-radius: $timer-radius;
    -webkit-mask-image: -webkit-linear-gradient( 0deg, rgba(0,0,0,1) 25%, rgba(0,0,0,0) 45%, rgba(0,0,0,0) 55%, rgba(0,0,0,1) 75% );

}

.TimerProgressBar {

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: $color-white;
    border-radius: $timer-radius;
    z-index: 10;

}

.TimerRemaining {

    @include no-select;

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-size: 36px;
    text-align: center;
    line-height: $timer-height;

}