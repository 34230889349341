
@import "../../../Import/SCSS/classes";

.ShareButton {

    $size: 40px;

    display: inline-block;
    position: relative;
    width: $size;
    height: $size;
    color: $color-white;
    line-height: $size;
    transition: all $duration-button ease;

    .Icon svg {

        stroke-width: 1;

    }

    @include hover {

        transform: scale( 1.1 );

    }

    @include active {

        transform: scale( 1 );

    }
    

}