
@import "../../../Import/SCSS/classes";

.Counter {

    @include no-select;

    position: relative;
    white-space: nowrap;
    z-index: 1;

}

.CounterChar {

    display: inline-block;
    position: relative;
    vertical-align: middle;

    span {

        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;

        &.Prev {

            animation: char-up-and-out .2s ease forwards;

        }

        &.Next {

            animation: char-up .2s ease forwards;

        }

    }

}

@keyframes char-up {

    0% {

        opacity: 0;
        transform: translateY(100%);

    }

    100% {

        opacity: 1;
        transform: translateY(0%);

    }

}

@keyframes char-up-and-out {

    0% {

        opacity: 1;
        transform: translateY(0%);

    }

    100% {

        opacity: 0;
        transform: translateY(-100%);

    }

}