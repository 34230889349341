
@import "../../../Import/SCSS/classes";

@mixin menu-item-active {

    &:before {

        opacity: .95;

    }

    span {

        transform: scale( 1 );

    }

}

@mixin menu-item-hover {

    &:before {

        opacity: 1;

    }

    span {

        color: $color-red;
        transform: scale( 1.1 );

        .GameDone & {

            

        }

    }

}

.Navigation {

    @include fade-up;
    @include no-select;

    text-align: center;

}

.NavigationItem {

    position: relative;
    max-width: 600px;
    margin: 20px auto;
    font-weight: 700;
    line-height: 100px;
    cursor: pointer;
    border: 2px solid $color-white;
    border-radius: 20px;

    span {

        display: inline-block;
        position: relative;
        transition: color $duration-button ease, transform $duration-button-double ease;
        
    }

    &:before {

        @include gradient-line;
        @include overlay;

        opacity: 0;
        transition: opacity $duration-button ease;
        content: "";

    }

    @include hover {

        color: $color-red;
        background-color: $color-white;

        //@include menu-item-hover;

    }

    @include active {

        //@include menu-item-active;

    }

}